import styled from "styled-components";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { useMealPlan } from "../../state/mealPlan";
import { BackSvg } from "../../assets/svg/BackSvg";
import { Colors } from "../../styles/global";
import { HeartFullSvg } from "../../assets/svg/HeartFullSvg";
import { HeartSvg } from "../../assets/svg/HeartSvg";
import { useState } from "react";
import { LoaderSvg } from "../../assets/svg/LoaderSvg";
import { useProfile } from "../../state/profile";
import { getCurrentDate } from "../../utils/datetime";
import { Api } from "../../apis/api";

const BackButton = styled.button`
  top: 1rem;
  left: 1rem;
  z-index: 10;
  width: 2rem;
  height: 2rem;
  position: fixed;
  border-radius: 50%;
  background: white;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const FavButton = styled.button`
  top: 1rem;
  right: 1rem;
  z-index: 10;
  width: 2rem;
  height: 2rem;
  padding-top: 2px;
  position: fixed;
  border-radius: 50%;
  background: white;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const MealImage = styled.img`
  width: 100%;
  max-height: 30vh;
  object-fit: cover;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  padding: 0 1rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  flex-direction: column;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 20px;
  line-height: 2rem;
  font-weight: 700;
`;

const Details = styled.p`
  font-size: 14px;
  color: #666;
  margin: 0;
  margin-bottom: 16px;
`;

const Disclaimer = styled.p`
  font-size: 14px;
  color: #666;
  margin: 0;
`;

const MacroContainer = styled.div`
  display: flex;
  margin: 1rem 0;
  padding: 1rem 0;
  border-radius: 1rem;
  border: 1px solid #f0f0f0;
  justify-content: space-around;
`;

const Macro = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const MacroValue = styled.div`
  color: #666;
  margin-bottom: 0.5rem;
`;

const MacroLabel = styled.div`
  color: #666;
  font-size: 12px;
`;

const MacroSeparator = styled.div`
  border-right: 1px solid #f0f0f0;
`;

const SectionTitle = styled.h2`
  font-size: 18px;
  margin-bottom: 0.5rem;
`;

const IngredientsList = styled.ul`
  padding: 0;
  list-style: none;
`;

const IngredientItem = styled.li`
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
`;

const Instructions = styled.ol`
  padding: 0;
  list-style: none; // Remove default list styling
`;

const InstructionStep = styled.li`
  display: flex;
  font-size: 14px;
  margin-bottom: 1rem;
`;

const Card = styled.div`
  display: flex;
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 5px;
  background: #FFFFFF;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
`;

const StepNumber = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px; // Adjust size as needed
  height: 24px; // Adjust size as needed
  border-radius: 50%;
  font-weight: 500;
  margin-top: -3px;
  margin-right: 1rem; // Adjust spacing as needed
  flex-shrink: 0; // Prevent flexbox from shrinking the circle
  background-color: rgba(0, 0, 0, 0.2);
`;

const PrimaryButton = styled.button`
  width: 100%;
  border: none;
  min-height: 50px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  color: ${Colors.fontPrimary};
  background-color: ${Colors.primary};
`;

const SecondaryButton = styled.button`
  width: 100%;
  border: none;
  min-height: 50px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  background-color: ${Colors.btnSecondary};
`;

export const MealView = () => {
  const navigate = useNavigate();
  const { mealId } = useParams();
  const { profile, update } = useProfile();
  const [params] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [doneLoading, setDoneLoading] = useState(false);
  const [undoneLoading, setUndoneLoading] = useState(false);
  const { meals, done, loved, toggleDone, toggleLove, changeMeal } = useMealPlan();
  const meal = meals[mealId as any];

  const markedDone = done[meal.mealId];
  const isLikedMeal = loved[meal.mealId];
  const mealToChangeId = params.get('mealToChangeId');

  if (!meal) {
    // Handle the case where the meal is not found
    return <div>Meal not found.</div>;
  }

  const markDone = async () => {
    try {
      setDoneLoading(true);
      if (!markedDone) {
        const date = getCurrentDate();
        let calories = { ...profile.claoriesConsumed };
        if (!calories[date]) calories[date] = {}
        calories[date] = {
          ...calories[date],
          [meal.mealId]: meal.caloriesPerServing,
        };
        await update({
          ...profile,
          claoriesConsumed: calories,
        });
        toggleDone(meal.mealId);
      }
      navigate(-1);
    } catch (e) {
      Api.reportError(e, {
        fn: 'onChangeMeal',
        email: profile.email,
      });
    } finally {
      setDoneLoading(false);
    }
  }

  const markUndone = async () => {
    try {
      setUndoneLoading(true);
      if (markedDone) {
        const date = getCurrentDate();
        let calories = { ...profile.claoriesConsumed };
        if (calories[date]?.[meal.mealId]) {
          calories[date] = {
            ...calories[date],
            [meal.mealId]: undefined,
          };
          await update({
            ...profile,
            claoriesConsumed: calories,
          });
        }
        toggleDone(meal.mealId);
      }
      navigate(-1);
    } catch (e) {
      Api.reportError(e, {
        fn: 'markUndone',
        email: profile.email,
      });
    } finally {
      setUndoneLoading(false);
    }
  }

  const onChangeMeal = async () => {
    try {
      setLoading(true);
      const day = params.get('day');
      const mealType = params.get('mealType');
      await changeMeal(day, mealType, mealToChangeId, mealId);
      navigate(-2);
    } catch (e) {
      Api.reportError(e, {
        fn: 'onChangeMeal',
        email: profile.email,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <BackButton onClick={() => navigate(-1)}>
        <BackSvg />
      </BackButton>
      <FavButton onClick={() => toggleLove(meal.mealId)}>
        {isLikedMeal ? <HeartFullSvg /> : <HeartSvg />}
      </FavButton>
      <MealImage src={meal.image} alt={meal.title} />
      <Content>
        <Title>{meal.title}</Title>
        <Details>
          {meal.prepTimeInMin} min · {meal.caloriesPerServing} kcal · {meal.macrosPerServing?.carbs} grams of carbs
        </Details>
        <MacroContainer>
          <Macro>
            <MacroValue><b>{meal.macrosPerServing?.fat}</b> g</MacroValue>
            <MacroLabel>Fat</MacroLabel>
          </Macro>
          <MacroSeparator />
          <Macro>
            <MacroValue><b>{meal.macrosPerServing?.carbs}</b> g</MacroValue>
            <MacroLabel>Carbs</MacroLabel>
          </Macro>
          <MacroSeparator />
          <Macro>
            <MacroValue><b>{meal.macrosPerServing?.protein}</b> g</MacroValue>
            <MacroLabel>Protein</MacroLabel>
          </Macro>
          <MacroSeparator />
          <Macro>
            <MacroValue><b>1</b></MacroValue>
            <MacroLabel>Serving</MacroLabel>
          </Macro>
        </MacroContainer>
        <SectionTitle>Description</SectionTitle>
        <span style={{ color: '#666' }}>{meal.description}</span>
        <SectionTitle>Ingredients</SectionTitle>
        <IngredientsList>
          {meal.ingredients?.map(ingredient => (
            <IngredientItem key={ingredient}>{ingredient}</IngredientItem>
          ))}
        </IngredientsList>
        <SectionTitle>Preparation instructions</SectionTitle>
        <Instructions>
          {meal.instructions?.map((step, index) => (
            <InstructionStep key={index}>
              <StepNumber>{index + 1}</StepNumber>
              {step}
            </InstructionStep>
          ))}
        </Instructions>
        <Card>
          <Disclaimer>
            * Your cooked meal might look different than the picture since ingredient proportions are adjusted to your specific goals
          </Disclaimer>
        </Card>
        {mealToChangeId ? (
          <PrimaryButton onClick={onChangeMeal}>
            {loading ? <LoaderSvg /> : 'Change meal'}
          </PrimaryButton>
        ) : (
          <>
            <PrimaryButton disabled={undoneLoading} onClick={markDone}>
              {doneLoading ? <LoaderSvg /> : 'Mark as done'}
            </PrimaryButton>
            <SecondaryButton disabled={doneLoading} onClick={markUndone}>
              {undoneLoading ? <LoaderSvg /> : 'Unmark as done'}
            </SecondaryButton>
          </>
        )}
      </Content>
    </>
  );
};
