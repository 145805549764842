import styled from "styled-components"
import { LeftHeader } from "../../components/nav/LeftHeader"
import { TabBar } from "../../components/nav/TabBar"
import Lottie from "react-lottie-player";
import bakerJson from '../../assets/lottie/baker.json';
import { useMealPlan } from "../../state/mealPlan";
import { useProfile } from "../../state/profile";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Content = styled.div`
  display: flex;
  height: 95vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.h2`
  max-width: 80%;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
`;

const Subtitle = styled.span`
  color: #666;
  max-width: 70%;
  font-size: 12px;
  text-align: center;
`;

export const MealPlanInitial = () => {
  const navigate = useNavigate();
  const { profile } = useProfile();
  const { mealPlan, fetchMealPlan } = useMealPlan();

  useEffect(() => {
    if (!mealPlan && profile.mealPlanId) {
      fetchMealPlan(profile.mealPlanId);
    }
    if (mealPlan?.status === 'ready') {
      navigate('/meals/plan');
    }
  }, [profile, mealPlan, fetchMealPlan, navigate]);

  return (
    <>
      <LeftHeader title="Meal plan" />
      <Content>
        <Lottie
          loop={true}
          play={true}
          style={{ width: 250 }}
          animationData={bakerJson}
        />
        <Title>Your plan is on your way</Title>
        <Subtitle>
          It may take up to 30 minutes to make the best plan for you. So please be patient 🙂
        </Subtitle>
      </Content>
      <TabBar />
    </>
  )
}
