import { FC, useState } from 'react';
import styled from 'styled-components';
import { useMealPlan } from '../../state/mealPlan';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { HeartSvg } from '../../assets/svg/HeartSvg';
import { HeartFullSvg } from '../../assets/svg/HeartFullSvg';
import { LoaderSvg } from '../../assets/svg/LoaderSvg';
import { Colors } from '../../styles/global';
import { Api } from '../../apis/api';
import { useProfile } from '../../state/profile';

const Container = styled.div`
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-bottom: 20px;
`;

const CardImage = styled.img`
  width: 100%;
  height: 160px; // adjust the height as needed
  object-fit: cover;
`;

const CardContent = styled.div`
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const CardImageContent = styled.div`
  position: relative;
`;

const CardTitle = styled.h2`
  font-size: 18px;
  margin: 0;
  margin-bottom: 8px;
`;

const CardDetails = styled.p`
  font-size: 14px;
  color: #666;
  margin: 0;
  margin-bottom: 16px;
`;

const ActionButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: #555;
  border: none;
  padding: 1rem;
  width: 100%;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
`;

const ChangeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: #555;
  border: none;
  width: 100%;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const FavButton = styled.button`
  top: 0.5rem;
  right: 0.5rem;
  z-index: 10;
  width: 2rem;
  height: 2rem;
  padding-top: 2px;
  position: absolute;
  border-radius: 50%;
  background: white;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

interface Props {
  day: string;
  mealId: string;
  mealType: string;
  mealToChangeId: string;
}

export const ChangeMealCard: FC<Props> = ({
  day,
  mealId,
  mealType,
  mealToChangeId
}) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const { profile } = useProfile();
  const { meals, loved, toggleLove, changeMeal } = useMealPlan();
  const meal = meals[mealId as any];
  const isLikedMeal = loved[meal.mealId];

  const onChangeMeal = async () => {
    try {
      setLoading(true);
      const day = params.get('day');
      const mealType = params.get('mealType');
      await changeMeal(day, mealType, mealToChangeId, mealId);
      navigate(-1);
    } catch (e) {
      Api.reportError(e, {
        fn: 'onChangeMeal',
        email: profile.email,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <FavButton onClick={() => toggleLove(meal.mealId)}>
        {isLikedMeal ? <HeartFullSvg/> : <HeartSvg />}
      </FavButton>
      <CardImageContent>
        <CardImage src={meal.image} alt={meal.title} />
      </CardImageContent>
      <CardContent>
        <CardTitle>{meal.title}</CardTitle>
        <CardDetails>
          {meal.prepTimeInMin} min · {meal.caloriesPerServing} kcal · {meal.macrosPerServing?.carbs} grams of carbs
        </CardDetails>
        <ActionsContainer>
          <ChangeButton onClick={onChangeMeal}>
            {loading ? <LoaderSvg c={Colors.primary} /> : 'CHANGE TO THIS'}
          </ChangeButton>
          <ActionButton to={`/meals/plan/${meal.mealId}?mealToChangeId=${mealToChangeId}&day=${day}&mealType=${mealType}`}>VIEW</ActionButton>
        </ActionsContainer>
      </CardContent>
    </Container>
  );
};
