export const GlassSvg = () => (
  <svg width="30.488" height="50" viewBox="0 0 30.488 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_63_2)">
      <path d="M1.915 17.618c4.402 3.707 9.402 2.484 14.516 1.22 3.764 -0.919 7.585 -1.854 11.317 -0.89l0.159 0.041 -2.069 26.614a4.545 4.545 0 0 1 -1.285 2.87 3.846 3.846 0 0 1 -2.74 1.142H8.569a3.76 3.76 0 0 1 -2.699 -1.126 4.85 4.85 0 0 1 -1.329 -2.882L1.537 17.301z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1.789 18.618c7.724 6.504 17.142 -1.878 25.911 0.407l-2.057 26.447c-0.163 2.102 -1.724 3.833 -3.829 3.833H8.569c-2.106 0 -3.602 -1.74 -3.829 -3.833z" fill="#027AFF" />
      <path d="M0 5.736a0.61 0.61 0 0 1 0 -0.191A2.033 2.033 0 0 1 0 5.285c0 -1.598 1.789 -2.967 4.679 -3.894C7.346 0.528 11.016 0 15.041 0s7.724 0.528 10.39 1.386c2.89 0.927 4.675 2.297 4.675 3.894a2.236 2.236 0 0 1 -0.13 0.748l-3.659 39.484a5.142 5.142 0 0 1 -1.439 3.167 4.264 4.264 0 0 1 -3.049 1.272H8.569a4.22 4.22 0 0 1 -3.041 -1.268A5.203 5.203 0 0 1 4.065 45.529zM1.549 7.724l3.854 37.679a3.874 3.874 0 0 0 1.069 2.354 2.927 2.927 0 0 0 2.098 0.874h13.244a2.959 2.959 0 0 0 2.11 -0.878 3.829 3.829 0 0 0 1.057 -2.341l3.496 -37.63a11.626 11.626 0 0 1 -3.045 1.415C22.764 10.037 19.106 10.569 15.041 10.569s-7.724 -0.528 -10.374 -1.386A11.382 11.382 0 0 1 1.549 7.724m27.114 -1.976 0.028 -0.264c0.007 -0.091 0.033 -0.18 0.077 -0.26 -0.073 -0.923 -1.492 -1.829 -3.748 -2.553C22.484 1.841 18.959 1.337 15.041 1.337S7.622 1.841 5.085 2.654C2.768 3.398 1.337 4.333 1.337 5.285s1.431 1.886 3.748 2.63c2.537 0.813 6.061 1.317 9.968 1.317s7.431 -0.504 9.968 -1.317c1.951 -0.626 3.252 -1.386 3.659 -2.179z" fill="black" />
      <path d="M5.878 25.525a0.668 0.668 0 1 1 1.329 -0.134L7.724 30.431a0.667 0.667 0 1 1 -1.325 0.134l-0.516 -5.041zM6.76 34.147a0.668 0.668 0 1 1 1.329 -0.138l0.923 9.033a0.671 0.671 0 0 1 -0.856 0.708 0.663 0.663 0 0 1 -0.47 -0.57z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_63_2">
        <path width="74.06" height="122.88" fill="white" d="M0 0H30.106V49.952H0V0z" />
      </clipPath>
    </defs>
  </svg>
);
