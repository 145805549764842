export const weights: string[] = [
  '88 lbs - 40 kg', '90 lbs - 41 kg', '93 lbs - 42 kg', '95 lbs - 43 kg',
  '97 lbs - 44 kg', '99 lbs - 45 kg', '101 lbs - 46 kg', '104 lbs - 47 kg',
  '106 lbs - 48 kg', '108 lbs - 49 kg', '110 lbs - 50 kg', '112 lbs - 51 kg',
  '115 lbs - 52 kg', '117 lbs - 53 kg', '119 lbs - 54 kg', '121 lbs - 55 kg',
  '123 lbs - 56 kg', '125 lbs - 57 kg', '128 lbs - 58 kg', '130 lbs - 59 kg',
  '132 lbs - 60 kg', '134 lbs - 61 kg', '137 lbs - 62 kg', '139 lbs - 63 kg',
  '141 lbs - 64 kg', '143 lbs - 65 kg', '145 lbs - 66 kg', '148 lbs - 67 kg',
  '150 lbs - 68 kg', '152 lbs - 69 kg', '154 lbs - 70 kg', '156 lbs - 71 kg',
  '159 lbs - 72 kg', '161 lbs - 73 kg', '163 lbs - 74 kg', '165 lbs - 75 kg',
  '167 lbs - 76 kg', '170 lbs - 77 kg', '172 lbs - 78 kg', '174 lbs - 79 kg',
  '176 lbs - 80 kg', '179 lbs - 81 kg', '181 lbs - 82 kg', '183 lbs - 83 kg',
  '185 lbs - 84 kg', '187 lbs - 85 kg', '190 lbs - 86 kg', '192 lbs - 87 kg',
  '194 lbs - 88 kg', '196 lbs - 89 kg', '199 lbs - 90 kg', '201 lbs - 91 kg',
  '203 lbs - 92 kg', '205 lbs - 93 kg', '207 lbs - 94 kg', '210 lbs - 95 kg',
  '212 lbs - 96 kg', '214 lbs - 97 kg', '216 lbs - 98 kg', '218 lbs - 99 kg',
  '221 lbs - 100 kg', '223 lbs - 101 kg', '225 lbs - 102 kg', '227 lbs - 103 kg',
  '229 lbs - 104 kg', '232 lbs - 105 kg', '234 lbs - 106 kg', '236 lbs - 107 kg',
  '238 lbs - 108 kg', '240 lbs - 109 kg', '243 lbs - 110 kg', '245 lbs - 111 kg',
  '247 lbs - 112 kg', '249 lbs - 113 kg', '251 lbs - 114 kg', '254 lbs - 115 kg',
  '256 lbs - 116 kg', '258 lbs - 117 kg', '260 lbs - 118 kg', '262 lbs - 119 kg',
  '265 lbs - 120 kg', '267 lbs - 121 kg', '269 lbs - 122 kg', '271 lbs - 123 kg',
  '273 lbs - 124 kg', '276 lbs - 125 kg', '278 lbs - 126 kg', '280 lbs - 127 kg',
  '282 lbs - 128 kg', '285 lbs - 129 kg', '287 lbs - 130 kg', '289 lbs - 131 kg',
  '291 lbs - 132 kg', '293 lbs - 133 kg', '296 lbs - 134 kg', '298 lbs - 135 kg',
  '300 lbs - 136 kg', '302 lbs - 137 kg', '304 lbs - 138 kg', '307 lbs - 139 kg',
  '309 lbs - 140 kg', '311 lbs - 141 kg', '313 lbs - 142 kg', '315 lbs - 143 kg',
  '318 lbs - 144 kg', '320 lbs - 145 kg', '322 lbs - 146 kg', '324 lbs - 147 kg',
  '326 lbs - 148 kg', '329 lbs - 149 kg', '331 lbs - 150 kg', '333 lbs - 151 kg',
  '335 lbs - 152 kg', '337 lbs - 153 kg', '340 lbs - 154 kg', '342 lbs - 155 kg',
  '344 lbs - 156 kg', '346 lbs - 157 kg', '348 lbs - 158 kg', '351 lbs - 159 kg',
  '353 lbs - 160 kg', '355 lbs - 161 kg', '357 lbs - 162 kg', '359 lbs - 163 kg',
  '362 lbs - 164 kg', '364 lbs - 165 kg', '366 lbs - 166 kg', '368 lbs - 167 kg',
  '370 lbs - 168 kg', '373 lbs - 169 kg', '375 lbs - 170 kg', '377 lbs - 171 kg',
  '379 lbs - 172 kg', '381 lbs - 173 kg', '384 lbs - 174 kg', '386 lbs - 175 kg',
  '388 lbs - 176 kg', '390 lbs - 177 kg', '392 lbs - 178 kg', '395 lbs - 179 kg',
  '397 lbs - 180 kg', '399 lbs - 181 kg', '401 lbs - 182 kg', '403 lbs - 183 kg',
  '406 lbs - 184 kg', '408 lbs - 185 kg', '410 lbs - 186 kg', '412 lbs - 187 kg',
  '414 lbs - 188 kg', '417 lbs - 189 kg', '419 lbs - 190 kg', '421 lbs - 191 kg',
  '423 lbs - 192 kg', '425 lbs - 193 kg', '428 lbs - 194 kg', '430 lbs - 195 kg',
  '432 lbs - 196 kg', '434 lbs - 197 kg', '436 lbs - 198 kg', '439 lbs - 199 kg',
  '441 lbs - 200 kg'
];
