import { FC } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { BackSvg } from '../../assets/svg/BackSvg';

const Container = styled.div`
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  padding: 1rem;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  color: #000;
  margin: 0;
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 600;
`;

const BackButton = styled.button`
  top: 50%;
  left: 1rem;
  position: absolute;
  background: none;
  border: none;
  transform: translateY(-40%);
  cursor: pointer;
`;

interface Props {
  title: string;
}

export const BackHeader: FC<Props> = ({ title }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <BackButton onClick={() => navigate(-1)}>
        <BackSvg/>
      </BackButton>
      <Title>{title}</Title>
    </Container>
  );
};
