export const heights: string[] = [
  '4 ft - 122 cm',
  '4 ft 1 in - 123 cm',
  '4 ft 1 in - 124 cm',
  '4 ft 2 in - 125 cm',
  '4 ft 2 in - 126 cm',
  '4 ft 3 in - 127 cm',
  '4 ft 3 in - 128 cm',
  '4 ft 4 in - 129 cm',
  '4 ft 4 in - 130 cm',
  '4 ft 5 in - 131 cm',
  '4 ft 5 in - 132 cm',
  '4 ft 6 in - 133 cm',
  '4 ft 0 in - 122 cm',
  '4 ft 0.4 in - 123 cm',
  '4 ft 0.8 in - 124 cm',
  '4 ft 1.2 in - 125 cm',
  '4 ft 1.6 in - 126 cm',
  '4 ft 2 in - 127 cm',
  '4 ft 2.4 in - 128 cm',
  '4 ft 2.8 in - 129 cm',
  '4 ft 3.2 in - 130 cm',
  '4 ft 3.6 in - 131 cm',
  '4 ft 4 in - 132 cm',
  '4 ft 4.4 in - 133 cm',
  '4 ft 4.8 in - 134 cm',
  '4 ft 5.2 in - 135 cm',
  '4 ft 5.6 in - 136 cm',
  '4 ft 6 in - 137 cm',
  '4 ft 6.4 in - 138 cm',
  '4 ft 6.8 in - 139 cm',
  '4 ft 7.2 in - 140 cm',
  '4 ft 7.6 in - 141 cm',
  '4 ft 8 in - 142 cm',
  '4 ft 8.4 in - 143 cm',
  '4 ft 8.8 in - 144 cm',
  '4 ft 9.2 in - 145 cm',
  '4 ft 9.6 in - 146 cm',
  '4 ft 10 in - 147 cm',
  '4 ft 10.4 in - 148 cm',
  '4 ft 10.8 in - 149 cm',
  '4 ft 11.2 in - 150 cm',
  '4 ft 11.6 in - 151 cm',
  '5 ft 0 in - 152 cm',
  '5 ft 0.4 in - 153 cm',
  '5 ft 0.8 in - 154 cm',
  '5 ft 1.2 in - 155 cm',
  '5 ft 1.6 in - 156 cm',
  '5 ft 2 in - 157 cm',
  '5 ft 2.4 in - 158 cm',
  '5 ft 2.8 in - 159 cm',
  '5 ft 3.2 in - 160 cm',
  '5 ft 3.6 in - 161 cm',
  '5 ft 4 in - 162 cm',
  '5 ft 4.4 in - 163 cm',
  '5 ft 4.8 in - 164 cm',
  '5 ft 5.2 in - 165 cm',
  '5 ft 5.6 in - 166 cm',
  '5 ft 6 in - 167 cm',
  '5 ft 6.4 in - 168 cm',
  '5 ft 6.8 in - 169 cm',
  '5 ft 7.2 in - 170 cm',
  '5 ft 7.6 in - 171 cm',
  '5 ft 8 in - 172 cm',
  '5 ft 8.4 in - 173 cm',
  '5 ft 8.8 in - 174 cm',
  '5 ft 9.2 in - 175 cm',
  '5 ft 9.6 in - 176 cm',
  '5 ft 10 in - 177 cm',
  '5 ft 10.4 in - 178 cm',
  '5 ft 10.8 in - 179 cm',
  '5 ft 11.2 in - 180 cm',
  '5 ft 11.6 in - 181 cm',
  '6 ft 0 in - 182 cm',
  '6 ft 0.4 in - 183 cm',
  '6 ft 0.8 in - 184 cm',
  '6 ft 1.2 in - 185 cm',
  '6 ft 1.6 in - 186 cm',
  '6 ft 2 in - 187 cm',
  '6 ft 2.4 in - 188 cm',
  '6 ft 2.8 in - 189 cm',
  '6 ft 3.2 in - 190 cm',
  '6 ft 3.6 in - 191 cm',
  '6 ft 4 in - 192 cm',
  '6 ft 4.4 in - 193 cm',
  '6 ft 4.8 in - 194 cm',
  '6 ft 5.2 in - 195 cm',
  '6 ft 5.6 in - 196 cm',
  '6 ft 6 in - 197 cm',
  '6 ft 6.4 in - 198 cm',
  '6 ft 6.8 in - 199 cm',
  '6 ft 7.2 in - 200 cm',
  '6 ft 7.6 in - 201 cm',
  '6 ft 8 in - 202 cm',
  '6 ft 8.4 in - 203 cm',
  '6 ft 8.8 in - 204 cm',
  '6 ft 9.2 in - 205 cm',
  '6 ft 9.6 in - 206 cm',
  '6 ft 10 in - 207 cm',
  '6 ft 10.4 in - 208 cm',
  '6 ft 10.8 in - 209 cm',
  '6 ft 11.2 in - 210 cm',
  '6 ft 11.6 in - 211 cm',
  '7 ft 0 in - 212 cm',
  '7 ft 0.4 in - 213 cm',
  '7 ft 0.8 in - 214 cm',
  '7 ft 1.2 in - 215 cm',
  '7 ft 1.6 in - 216 cm',
  '7 ft 2 in - 217 cm',
  '7 ft 2.4 in - 218 cm',
  '7 ft 2.8 in - 219 cm',
  '7 ft 3.2 in - 220 cm',
  '7 ft 3.6 in - 221 cm',
  '7 ft 4 in - 222 cm',
  '7 ft 4.4 in - 223 cm',
  '7 ft 4.8 in - 224 cm',
  '7 ft 5.2 in - 225 cm',
  '7 ft 5.6 in - 226 cm',
  '7 ft 6 in - 227 cm',
  '7 ft 6.4 in - 228 cm',
  '7 ft 6.8 in - 229 cm',
  '7 ft 7.2 in - 230 cm',
  '7 ft 7.6 in - 231 cm',
  '7 ft 8 in - 232 cm',
  '7 ft 8.4 in - 233 cm',
  '7 ft 8.8 in - 234 cm',
  '7 ft 9.2 in - 235 cm',
  '7 ft 9.6 in - 236 cm',
  '7 ft 10 in - 237 cm',
  '7 ft 10.4 in - 238 cm',
  '7 ft 10.8 in - 239 cm',
  '7 ft 11.2 in - 240 cm'
];
