import { AxiosError } from "axios";
import { ValidationError } from "yup";

export interface IAppError {
  [key: string]: string;
  error?: string;
}

export const getErrors = (
  error?: ValidationError | AxiosError | Error | unknown
): IAppError => {
  // Handle Yup ValidationError
  if (error instanceof ValidationError) {
    const messages: Record<string, string> = {};
    // If it's a ValidationError, it might have an array of inner errors
    error.inner.forEach(e => {
      if (e.path) {
        messages[e.path] = e.message;
      }
    });
    return messages;
  }

  // Handle AxiosError
  if (error instanceof AxiosError) {
    // Check if the error has a response with data
    if (error.response && error.response.data) {
      return { error: error.response.data.message };
    }
    return { error: error.message };
  }

  // Handle generic Error (like standard JavaScript errors)
  if (error instanceof Error) {
    return { error: error.message };
  }

  // Handle non-error values that might be thrown
  if (typeof error === 'string' || typeof error === 'number') {
    return { error: String(error) };
  }

  // Default case for other types or when error is undefined
  return { error: 'An unknown error occurred' };
}
