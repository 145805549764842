import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import styled from "styled-components";
import { Colors } from "../../styles/global";
import { format } from "date-fns";
import { useState } from "react";
import { useProfile } from "../../state/profile";
import { convertMlToOunces, getPercentage } from "../../utils/units";
import { getCurrentDate } from "../../utils/datetime";
import { sumDailyCalories } from "../../utils/calories";

const Container = styled.div`
  display: flex;
  padding-top: 2rem;
  padding-left: 3rem;
  padding-right: 3rem;
  border-radius: 1rem;
  align-items: center;
  flex-direction: column;
`;

const Content = styled.div`
  gap: 1.5rem;
  height: 65%;
  display: flex;
  font-family: Rubik;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const Text = styled.span`
  font-size: 1rem;
  font-weight: 400;
`;

const TextBold = styled.span`
  font-weight: 500;
  font-size: 1.5rem;
`;

const ActionsContainer = styled.div`
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CaloriesButton = styled.button<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: #555;
  border: none;
  padding: 0.5rem 1rem;
  width: 100%;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
  border: ${Colors.primaryBorder(2)};
  background-color: ${p => p?.$active && Colors.primary};
`;

const WaterButton = styled.button<{ $active?: boolean }>`
  display: flex;
  background: none;
  border: none;
  width: 100%;
  cursor: pointer;
  font-weight: bold;
  align-items: center;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  padding: 0.5rem 1rem;
  text-decoration: none;
  justify-content: center;
  border: ${Colors.waterBorder(2)};
  color: ${p => p?.$active ? 'white' : '#555'};
  background-color: ${p => p?.$active && Colors.waterBlue};
`;

export const GoalGraph = () => {
  const { profile } = useProfile();
  const [tab, setTab] = useState('calories');
  const [month, day] = format(new Date(), 'MMM d, eeee').split(',');

  let fluidUnits = 'ml';
  let waterIntake = profile.waterIntake || 2000;
  let waterConsumed = profile.waterConsumed?.[getCurrentDate()] || 0;

  if (profile.units === 'imperial') {
    fluidUnits = 'fl oz';
    waterIntake = convertMlToOunces(waterIntake);
    waterConsumed = convertMlToOunces(waterConsumed);
  }

  const waterPercentage = getPercentage(waterConsumed, waterIntake);
  const caloriesConsumed = sumDailyCalories(profile.claoriesConsumed);
  const caloriePercentage = getPercentage(caloriesConsumed, profile.dailyCalories);

  return (
    <Container>
      <CircularProgressbarWithChildren
        value={caloriePercentage}
        strokeWidth={5}
        circleRatio={0.75}
        styles={buildStyles({
          rotation: 1 / 2 + 1 / 8,
          trailColor: "#eee",
          pathColor: Colors.primary,
        })}
      >
        <div style={{ width: "85%" }}>
          <CircularProgressbarWithChildren
            strokeWidth={5}
            circleRatio={0.75}
            value={waterPercentage}
            styles={buildStyles({
              rotation: 1 / 2 + 1 / 8,
              trailColor: '#eee',
              pathColor: Colors.waterBlue,
            })}
          >
            <Content>
              <Flex>
                <Text>{month}</Text>
                <TextBold>{day}</TextBold>
              </Flex>
              {tab === 'water' && (
                <Flex style={{ gap: '0.5rem' }}>
                  <TextBold>{waterConsumed} / {waterIntake}</TextBold>
                  <TextBold>{fluidUnits}</TextBold>
                </Flex>
              )}
              {tab === 'calories' && (
                <Flex style={{ gap: '0.5rem' }}>
                  <TextBold>{caloriesConsumed} / {profile.dailyCalories}</TextBold>
                  <TextBold>kCal</TextBold>
                </Flex>
              )}
            </Content>
          </CircularProgressbarWithChildren>
        </div>
      </CircularProgressbarWithChildren>
      <ActionsContainer>
        <CaloriesButton $active={tab === 'calories'} onClick={() => setTab('calories')}>
          Calories
        </CaloriesButton>
        <WaterButton $active={tab === 'water'} onClick={() => setTab('water')}>
          Water
        </WaterButton>
      </ActionsContainer>
    </Container>
  );
}
