import { FC } from 'react';
import Chart, { Props as ApexOptions } from 'react-apexcharts';
import { Colors } from '../../styles/global';
import styled from 'styled-components';

let options = {
  chart: {
    type: 'area',
    height: 300,
    foreColor: Colors.fontPrimary,
    animations: {
      speed: 500,
    },
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    }
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
    colors: [Colors.primary],
  },
  fill: {
    shadeIntensity: 1,
    opacityFrom: 0.7,
    opacityTo: 0.9,
    stops: [0, 90, 100],
    colors: [Colors.primary]
  },
  grid: {
    padding: {
      right: 24 // Adjust this value as needed
    }
  },
  tooltip: {
    x: {
      format: 'MMM d',
    },
  },
  annotations: {
    yaxis: [{
      y: 85,
      borderWidth: 2,
      strokeDashArray: 0,
      borderColor: Colors.waterBlue,
      label: {
        text: 'Goal',
        borderColor: Colors.waterBlue,
        style: {
          color: '#fff',
          background: Colors.waterBlue,
        },
      }
    }]
  }
} as ApexOptions['options'];

const Container = styled.div`
  flex: 1;
  display: flex;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 12px;
  background: #FFFFFF;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
`;

const Button = styled.button`
  width: 100%;
  border: none;
  min-height: 50px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  border-radius: 0.5rem;
  color: ${Colors.fontPrimary};
  background-color: ${Colors.primary};
`;

interface Props {
  goal: number;
  units: 'metric' | 'imperial';
  weights: number[],
  datetime: string[],
  onClick: () => void;
}

export const WeightGraph: FC<Props> = ({
  goal,
  units,
  weights,
  datetime,
  onClick
}) => {
  options.annotations.yaxis[0].y = goal;
  options = {
    ...options,
    yaxis: {
      min: goal - 10,
      labels: {
        formatter: (value: number) => `${Math.floor(value)} ${units === 'metric' ? 'kg' : 'lbs'}`
      }
    },
  }

  const xaxis: ApexXAxis = {
    type: 'datetime',
    categories: datetime,
    stepSize: 1,
    labels: {
      minHeight: 40,
      format: 'MMM d',
      rotateAlways: true,
      showDuplicates: false,
    },
  }
  const series: ApexOptions['series'] = [{
    name: 'Weight',
    data: weights,
  }];

  return (
    <Container>
      <div id="chart" style={{ width: '100%' }}>
        <Chart
          type="area"
          height={300}
          series={series}
          options={{
            ...options,
            xaxis
          }}
        />
      </div>
      <Button onClick={onClick}>
        Track weight
      </Button>
    </Container>
  );
}
