import { createRoot } from 'react-dom/client';
import { Router } from './pages';
import styled, { createGlobalStyle } from 'styled-components';
import { Api } from './apis/api';

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    height: 100%;
    width: 100%;
    overflow: auto;
    font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ::-webkit-scrollbar {
    display: none;
  }
  * {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
`;

const root = createRoot(
  document.getElementById('root') as HTMLElement
);

const Container = styled.div`
  height: 100vh;
  width: 100vw;
`;

window.onerror = (event, source, lineno, colno, e) => {
  Api.reportError(e, {
    fn: 'dom',
    email: 'N/A',
  });
}

root.render(
  <Container>
    <GlobalStyles />
    <Router />
  </Container>
);
