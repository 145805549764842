import styled from "styled-components"
import { LeftHeader } from "../../components/nav/LeftHeader"
import { TabBar } from "../../components/nav/TabBar";
import { Link, useNavigate } from "react-router-dom";
import { Colors } from "../../styles/global";
import listJson from '../../assets/lottie/shoppingList.json'
import generatingJson from '../../assets/lottie/generatingShoppingList.json'
import Lottie from "react-lottie-player";
import { useShoppingList } from "../../state/shoppingList";
import { useMealPlan } from "../../state/mealPlan";
import { useEffect } from "react";

const Content = styled.div`
  display: flex;
  height: 95vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.h2`
  max-width: 80%;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
`;

const Subtitle = styled.span`
  color: #666;
  max-width: 70%;
  font-size: 12px;
  text-align: center;
`;

const Button = styled(Link)<{ disabled?: boolean }>`
  margin-top: 1rem;
  font-weight: bold;
  border-radius: 0.5rem; // Rounded corners
  padding: 0.75rem 2rem;
  cursor: pointer;
  border: none;
  text-decoration: none;
  transition: all 0.3s ease;
  color: ${Colors.fontPrimary};
  background-color: ${Colors.primary};
  opacity: ${p => p.disabled ? 0.6 : 1};
  pointer-events: ${p => p.disabled ? 'none' : 'all'};
`;

export const ShoppingListInitial = () => {
  const navigate = useNavigate();
  const { mealPlan } = useMealPlan();
  const { shoppingList } = useShoppingList();
  const allowToCreate = mealPlan && mealPlan?.status === 'ready';
  const showLoading = shoppingList && shoppingList?.status === 'generating';

  useEffect(() => {
    if (shoppingList?.status === 'ready') {
      navigate('/shopping/list');
    }
  }, [shoppingList?.status, navigate])

  return (
    <>
      <LeftHeader title="Shopping list" />
      <Content>
        {showLoading && (
          <>
            <Lottie
              loop = { true }
              play = { true }
              animationData = {generatingJson}
              style = {{ width: 300 }}
            />
            <Title>We're generating your shopping List</Title>
            <Subtitle>
              It may take up to 5 minutes.
            </Subtitle>
          </>
        )}
        {!showLoading && (
          <>
            <Lottie
              loop = { true }
              play = { true }
              animationData = {listJson}
              style = {{ width: 300 }}
            />
            <Title>Shopping List</Title>
            <Subtitle>
              Select days you’d like to shop for to see your full Keto shopping list.
            </Subtitle>
            <Button disabled={!allowToCreate} to="/shopping/create">
              Create List
            </Button>
          </>
        )}
      </Content>
      <TabBar />
    </>
  )
}
