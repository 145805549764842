export const convertMlToOunces = (ml: number) => {
  const mlPerFluidOunce = 29.5735;
  return Math.floor(ml / mlPerFluidOunce);
}

export const convertOuncesToMl = (ounces: number) => {
  const mlPerFluidOunce = 29.5735;
  return Math.floor(ounces * mlPerFluidOunce);
}

export const getPercentage = (val: number, divider: number) => {
  if (divider === 0) return 0;

  const p = Math.floor(val / divider * 100);
  if (p >= 100) {
    return 100;
  }
  return Math.floor(val / divider * 100);
}
