import { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: #fff;
`;

const TopContent = styled.div`
  display: flex;
  padding: 1rem;
`;

const Title = styled.h1`
  font-size: 32px; /* Adjust the size as needed */
  color: #000;
  margin: 0;
`;

interface Props {
  title: string;
}

export const LeftHeader: FC<Props> = ({
  title
}) => (
  <Container>
    <TopContent>
      <Title>{title}</Title>
    </TopContent>
  </Container>
);
