import { FC } from "react";
import styled from "styled-components";
import { Checkbox } from "../inputs/Checkbox";
import { IIngredient } from "../../types/shoppingList";
import { capitalize } from "lodash";

const Container = styled.div<{ purchased?: boolean }>`
  display: flex;
  max-width: 100%;
  padding: 0.65rem 0;
  align-items: center;
  cursor: pointer;
  opacity: ${p => p.purchased ? 0.6 : 1};
`;

const Title = styled.span<{ purchased?: boolean }>`
  color: #000;
  font-size: 1rem;
  line-height: 1.35em;
  font-weight: 600;
  margin-left: 0.5rem;
  padding-right: 1rem;
  text-decoration: ${p => p.purchased ? 'line-through' : 'none'};
`;

const Quantity = styled.span<{ purchased?: boolean }>`
  margin: 0;
  color: #666;
  font-size: 14px;
  white-space: nowrap;
  text-decoration: ${p => p.purchased ? 'line-through' : 'none'};
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface Props extends IIngredient {
  onClick: () => void;
}

export const ShoppingItem: FC<Props> = ({
  title,
  quantity,
  purchased,
  onClick
}) => (
  <Container purchased={purchased} onClick={onClick}>
    <Checkbox active={purchased}/>
    <Row>
      <Title purchased={purchased}>{capitalize(title)}</Title>
      <Quantity purchased={purchased}>{quantity || 'to taste'}</Quantity>
    </Row>
  </Container>
)
