import { FC } from 'react';
import styled from 'styled-components';
import { capitalize } from 'lodash';
import { days } from '../../utils/days';
import { Colors } from '../../styles/global';

const Container = styled.div`
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  position: fixed;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const TopContent = styled.div`
  display: flex;
  padding: 1rem;
`;

const Title = styled.h1`
  font-size: 32px; /* Adjust the size as needed */
  color: #000;
  margin: 0;
`;

const TabsContainer = styled.div`
  display: flex;
  padding: 0 1rem;
  overflow: scroll;
  justify-content: flex-start;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Tab = styled.div`
  cursor: pointer;
  display: flex;
  font-size: 14px;
  margin-right: 1rem;
  padding-bottom: 8px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 3px solid transparent;

  &.active {
    color: ${Colors.primary};
    border-bottom-color: ${Colors.primary};
  }
`;

// const EditButton = styled.button`
//   margin-left: auto; /* Pushes the button to the right */
//   background: none;
//   border: none;
//   font-size: 14px;
//   line-height: 20px;
//   color: #000;
//   font-weight: 600;
//   cursor: pointer;

//   svg {
//     fill: #66589A;
//     margin-bottom: -2px;
//     margin-right: 5px;
//   }
// `;

interface Props {
  active: string;
  onTabChange: (tab: string) => void;
}

export const MealsHeader: FC<Props> = ({ active, onTabChange }) => (
  <Container>
    <TopContent>
      <Title>Meals</Title>
      {/* <EditButton>
        <EditSvg />
        EDIT
      </EditButton> */}
    </TopContent>
    <TabsContainer>
      {days.map(day => (
        <Tab
          key={day}
          onClick={() => onTabChange(day)}
          className={active === day ? 'active' : ''}
        >
          {capitalize(day)}
        </Tab>
      ))}
    </TabsContainer>
  </Container>
);
