import { startOfWeek, getWeek, getDayOfYear, format, addDays, subDays, parseISO, isWithinInterval } from 'date-fns';

export const getCurrentWeekNumber = () => {
  const currentDate = new Date();
  const startOfCurrentWeek = startOfWeek(currentDate, { weekStartsOn: 1 }); // Assuming week starts on Monday
  return getWeek(startOfCurrentWeek);
};

export const getCurrentDayOfTheYear = () => {
  const date = new Date();
  return getDayOfYear(date);
};

export const getCurrentDate = () => {
  return format(new Date(), 'yyyy-MM-dd');
};

export const getTomorrowsDate = () => {
  const tomorrow = addDays(new Date(), 1);
  return format(tomorrow, 'yyyy-MM-dd');
};

export const getLast7DaysData = (data: Record<string, number>): Record<string, number> => {
  const today = new Date();
  const sevenDaysAgo = subDays(today, 7);

  const last7DaysData = Object.keys(data).reduce((acc, date) => {
    const parsedDate = parseISO(date);
    if (isWithinInterval(parsedDate, { start: sevenDaysAgo, end: today })) {
      acc[date] = data[date];
    }
    return acc;
  }, {});

  return last7DaysData;
};

export const getLatestValue = (data: Record<string, number>) => {
  const dates = Object.keys(data);
  const latestDate = dates.sort((a, b) => {
    return new Date(b).getTime() - new Date(a).getTime();
  })[0];
  return data[latestDate];
};
