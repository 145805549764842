import { differenceInMinutes } from "date-fns";
import { IShoppingListState } from "../state/shoppingList";

export const shouldFetchList = (
  state: IShoppingListState,
  shoppingListId?: string
) => {
  const { lastFetched, shoppingList } = state;
  const now = new Date();
  const lastFetchedDate = new Date(lastFetched);

  const diff = differenceInMinutes(now, lastFetchedDate);
  const isGenerating = shoppingList?.status !== 'ready';
  console.log('shoppingDiff', diff);
  if (shoppingListId && !shoppingList) {
    return true;
  }
  return shoppingListId && diff > 3 && isGenerating;
}
