import { FC } from "react";
import styled from "styled-components";
import { Checkbox } from "../inputs/Checkbox";

const Container = styled.div`
  display: flex;
  max-width: 100%;
  padding: 0.5rem;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const Title = styled.span`
  color: #000;
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 600;
  margin-left: 0.5rem;
`;

interface Props {
  active: boolean;
  title: string;
  onClick: () => void;
}

export const CheckboxItem: FC<Props> = ({
  active,
  title,
  onClick
}) => (
  <Container onClick={onClick}>
    <Checkbox active={active} />
    <Title>{title}</Title>
  </Container>
)
