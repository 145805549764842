import { FC, useState } from "react";
import { ActionSheet } from "./ActionSheet"
import styled from "styled-components";
import { Colors } from "../../styles/global";
import { LoaderSvg } from "../../assets/svg/LoaderSvg";
import { GlassSvg } from "../../assets/svg/GlassSvg";
import { Counter } from "../inputs/Counter";

const Container = styled.div`
  gap: 2rem;
  display: flex;
  padding: 1rem 2rem;
  padding-bottom: 2rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.span`
  color: #666;
  max-width: 100%;
  font-size: 1rem;
  text-align: center;
`;

const Button = styled.button`
  width: 100%;
  border: none;
  min-height: 50px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  border-radius: 0.5rem;
  color: ${Colors.white};
  background-color: ${Colors.waterBlue};
`;

const Grid = styled.div`
  display: grid;
  width: 240px;
  height: auto;
  row-gap: 1rem;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(4, 1fr);
`;

// Styled grid item
const GridItem = styled.button<{ $active?: boolean }>`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  opacity: ${p => p.$active ? 1 : 0.4};
`;

const Content = styled.div`
  gap: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const CounterValue = styled.span`
  margin: 0 2rem;
  font-size: 24px;
  font-weight: 600;
  user-select: none;
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (value: number) => void;
  loading?: boolean;
}

const slots = new Array(8).fill(1);

export const TrackWaterSheet: FC<Props> = ({
  isOpen,
  onClose,
  onSave,
  loading,
}) => {
  const [ml, setMl] = useState(50);
  const [count, setCount] = useState(0);

  const submit = () => {
    const waterConsumed = ml * (count + 1);
    onSave(waterConsumed);
  }

  return (
    <ActionSheet isOpen={isOpen} onClose={onClose}>
      <Container>
        <Title>
          Select water amount you just drank
        </Title>
        <Content>
          <CounterValue>
            {ml * (count + 1)} ml
          </CounterValue>
          <Grid>
            {slots.map((_, i) => (
              <GridItem
                key={i}
                $active={count >= i}
                onClick={() => setCount(i)}
              >
                <GlassSvg />
              </GridItem>
            ))}
          </Grid>
          <Counter
            min={50}
            max={250}
            step={50}
            unit="ml"
            initial={ml}
            onChange={v => setMl(v)}
          />
        </Content>
        <Button onClick={submit}>
          {loading ? <LoaderSvg /> : 'Save'}
        </Button>
      </Container>
    </ActionSheet>
  );
}
