import { IProfile } from "../types/profile";

export const getFullName = (profile: IProfile) => {
  if (!profile.name && !profile.surname) {
    return '-';
  }
  return `${profile.name} ${profile.surname}`;
}

export const getInitials = (profile: IProfile) => {
  if (!profile.name && !profile.surname) {
    return '-';
  }
  if (profile.name && profile.surname) {
    const nameInitial = profile.name.charAt(0);
    const surnameInitial = profile.surname.charAt(0);
    return (nameInitial + surnameInitial).toLocaleUpperCase();
  }
  return `${profile.name} ${profile.surname}`;
}

// Helper functions for conversion
export const cmToInches = (cm: number) => parseFloat((cm / 2.54).toFixed(1));
export const kgToPounds = (kg: number) => parseFloat((kg * 2.20462).toFixed(1));
export const inchesToCm = (inches: number) => parseFloat((inches * 2.54).toFixed(1));
export const poundsToKg = (pounds: number) => parseFloat((pounds / 2.20462).toFixed(1));


// Function to format height based on the unit system
export const formatHeight = (profile: IProfile) => {
  if (profile.units === 'metric') {
    // Height in centimeters, no conversion needed
    return `${profile.height} cm`;
  } else {
    // Convert height to feet and inches
    const totalInches = cmToInches(profile.height);
    const feet = Math.floor(totalInches / 12);
    const inches = Math.round(totalInches % 12);
    return `${feet}' ${inches}"`; // Returns the height in format X' Y"
  }
};

export const formatHeightNumeric = (profile: IProfile) => {
  if (profile.units === 'metric') {
    // Height in centimeters, no conversion needed
    return profile.height;
  } else {
    // Convert height to feet and inches
    const totalInches = cmToInches(profile.height);
    return totalInches; // Returns the height in format X' Y"
  }
};

// Function to format weight based on the unit system
export const formatWeight = (profile: IProfile, field: 'weight' | 'goal' = 'weight') => {
  if (field === 'weight') {
    if (profile.units === 'metric') {
      // Weight in kilograms, no conversion needed
      return `${profile.weight} kg`;
    } else {
      // Convert weight to pounds
      const pounds = kgToPounds(profile.weight);
      return `${Math.round(pounds)} lbs`; // Rounds the pounds for simplicity
    }
  } else {
    if (profile.units === 'metric') {
      // Weight in kilograms, no conversion needed
      return `${profile.goal} kg`;
    } else {
      // Convert weight to pounds
      const pounds = kgToPounds(profile.goal);
      return `${Math.round(pounds)} lbs`; // Rounds the pounds for simplicity
    }
  }
};

export const formatWeightNumeric = (profile: IProfile, field: 'weight' | 'goal' = 'weight') => {
  if (field === 'weight') {
    if (profile.units === 'metric') {
      // Weight in kilograms, no conversion needed
      return profile.weight;
    } else {
      // Convert weight to pounds
      const pounds = kgToPounds(profile.weight);
      return Math.round(pounds); // Rounds the pounds for simplicity
    }
  } else {
    if (profile.units === 'metric') {
      // Weight in kilograms, no conversion needed
      return profile.goal;
    } else {
      // Convert weight to pounds
      const pounds = kgToPounds(profile.goal);
      return Math.round(pounds); // Rounds the pounds for simplicity
    }
  }
};
