import { FC } from 'react';
import styled from "styled-components";
import { MealCard } from '../cards/MealCard';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ItemTitle = styled.h2`
  margin: 0;
  font-size: 18px;
  margin-bottom: 8px;
`;

interface Props {
  day: string;
  title: string;
  mealType: string;
  mealIds: string[];
}

export const CarouselItem: FC<Props> = ({ day, title, mealType, mealIds }) => {
  return (
    <Container>
      <ItemTitle>{title}</ItemTitle>
      {mealIds.map(id => (
        id && <MealCard
          key={id}
          day={day}
          mealId={id}
          mealType={mealType}
        />
      ))}
    </Container>
  );
}
