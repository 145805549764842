import { differenceInMinutes } from "date-fns"
import { IMealPlanState } from "../state/mealPlan"
import { IMealPlan, IMealPlanDay } from "../types/mealplan"

export const getMealDay = (mealPlan: IMealPlan, day: string) => {
  const mealDays: Record<string, IMealPlanDay> = {
    monday: mealPlan.monday,
    tuesday: mealPlan.tuesday,
    wednesday: mealPlan.wednesday,
    thursday: mealPlan.thursday,
    friday: mealPlan.friday,
    saturday: mealPlan.saturday,
    sunday: mealPlan.sunday,
  }
  return mealDays[day]
}

export const shouldFetchMealPlan = (
  state: IMealPlanState,
  mealPlanId?: string
) => {
  const { lastFetched, mealPlan } = state;
  const now = new Date();
  const lastFetchedDate = new Date(lastFetched);

  const diff = differenceInMinutes(now, lastFetchedDate);
  const isGenerating = mealPlan?.status !== 'ready';
  console.log('planDiff', diff);
  if (mealPlanId && !mealPlan) {
    return true;
  }
  return mealPlanId && diff > 5 && isGenerating;
}
