import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

// Styled components
const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CounterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  margin: 0 10px;
  cursor: pointer;
  font-size: 24px;
  user-select: none;
  border-radius: 10px;
  background-color: #f0f0f0;

  &:focus {
    outline: none;
  }

  &:active {
    background-color: #e0e0e0;
  }
`;

const CounterValue = styled.span`
  margin: 0 2rem;
  font-size: 24px;
  font-weight: 600;
  user-select: none;
`;

interface Props {
  initial: number;
  step: number;
  max?: number;
  min?: number;
  unit?: string;
  onChange: (v: number) => void;
}

export const Counter: FC<Props> = ({
  initial,
  step,
  min,
  max,
  unit,
  onChange,
}) => {
  const [count, setCount] = useState<number>(initial); // Default value set to 2000

  const increment = () => {
    const newValue = count + step;
    if (typeof max === 'number' && newValue <= max) {
      setCount(newValue);
    } else if (!max) {
      setCount(newValue);
    }
  }

  const decrement = () => {
    const newValue = count - step;
    if (typeof min === 'number' && newValue >= min) {
      setCount(newValue);
    } else if (!min) {
      setCount(newValue);
    }
  }

  useEffect(() => {
    onChange(count);
  }, [count, onChange])

  return (
    <CounterContainer>
      <CounterButton onClick={decrement}>-</CounterButton>
      <CounterValue>{count} {unit}</CounterValue>
      <CounterButton onClick={increment}>+</CounterButton>
    </CounterContainer>
  );
};
