import { create } from "zustand";
import { IMealPlan, IMealPlanDay } from "../types/mealplan";
import { IMeal } from "../types/meal";
import { immer } from 'zustand/middleware/immer'
import { persist } from 'zustand/middleware'
import { Api } from "../apis/api";
import { getMealDay, shouldFetchMealPlan } from "../utils/mealPlan";
import { getCurrentWeekNumber } from "../utils/datetime";

const STORAGE_KEY = 'meal-plan';

export interface IMealPlanState {
  currentWeek: number | null;
  mealPlan: IMealPlan | null;
  meals: Record<string, IMeal>;
  lastFetched: string | null;
  changeMealTab: string;
  loved: Record<string, boolean>;
  done: Record<string, boolean>;
  setCurrentWeek: () => void;
  fetchMealPlan: (mealPlanId: string) => Promise<void>;
  getDayMeals: (day: string) => IMealPlanDay | null;
  toggleDone: (mealId: string) => void;
  toggleLove: (mealId: string) => void;
  setChangeMealTab: (tab: string) => void;
  changeMeal: (day: string, mealType: string, oldId: string, newId: string) => Promise<void>;
  getLikedMeals: (mealToChangeId: string) => IMeal[];
  getRecommendedMeals: (mealId: string) => IMeal[];
  resetMealPlan: () => void;
}

export const useMealPlan = create<IMealPlanState>()(
  immer(
    persist((set, get) => ({
      currentWeek: null,
      mealPlan: null,
      meals: {},
      lastFetched: null,
      changeMealTab: 'recommended',
      loved: {},
      done: {},
      setCurrentWeek() {
        const currentStateWeek = get().currentWeek;
        const currentWeek = getCurrentWeekNumber();

        if (currentStateWeek !== currentWeek) {
          set(s => {
            s.currentWeek = currentWeek;
            s.done = {};
          });
        }
      },
      getDayMeals(day) {
        const mealPlan = get().mealPlan;
        if (mealPlan) {
          return getMealDay(mealPlan, day);
        }
        return null;
      },
      resetMealPlan() {
        localStorage.removeItem(STORAGE_KEY);
        set(s => {
          s.mealPlan = null;
          s.meals = {};
          s.lastFetched = null;
        });
      },
      async fetchMealPlan(mealPlanId) {
        const state = get();
        if (shouldFetchMealPlan(state, mealPlanId)) {
          const [mealPlan, meals] = await Promise.all([
            Api.getMealPlan(mealPlanId),
            Api.getPlanMeals(mealPlanId),
          ]);
          set(s => {
            s.mealPlan = mealPlan;
            s.meals = meals;
            s.lastFetched = new Date().toISOString();
          });
        }
      },
      toggleDone(mealId) {
        set(s => {
          s.done[mealId] = !s.done[mealId];
        });
      },
      toggleLove(mealId) {
        set(s => {
          s.loved[mealId] = !s.loved[mealId];
        });
      },
      setChangeMealTab(tab: string) {
        set(s => {
          s.changeMealTab = tab;
        });
      },
      async changeMeal(day, mealType, oldId, newId) {
        set(s => {
          s.mealPlan[day][mealType] = s.mealPlan[day][mealType].map(id => id === oldId ? newId : id);
        });
        const mealPlan = get().mealPlan;
        await Api.updateMealPlan(mealPlan)
      },
      getLikedMeals(mealToChangeId) {
        const {meals, loved} = get();
        return Object.values(meals)
          .filter(m => loved[m.mealId] && m.mealId !== mealToChangeId) || [];
      },
      getRecommendedMeals(mealId) {
        const meals = get().meals;
        const mealArray = Object.values(meals);
        const recommended = new Set<IMeal>();
        if (meals[mealId].props?.breakfast) {
          const forBreakfast = mealArray.filter(m => m.props.breakfast);
          forBreakfast.forEach(m => {
            recommended.add(m);
          });
        }
        if (meals[mealId].props?.lunch) {
          const forLunch = mealArray.filter(m => m.props.lunch);
          forLunch.forEach(m => {
            recommended.add(m);
          });
        }
        if (meals[mealId].props?.dinner) {
          const forDinner = mealArray.filter(m => m.props.dinner);
          forDinner.forEach(m => {
            recommended.add(m);
          });
        }
        if (meals[mealId].props?.snack) {
          const forSnack = mealArray.filter(m => m.props.snack);
          forSnack.forEach(m => {
            recommended.add(m);
          });
        }
        recommended.delete(meals[mealId]);
        return Array.from(recommended);
      }
    }),
      {
        name: STORAGE_KEY,
      }
    )
  )
);
