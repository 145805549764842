import styled from "styled-components";

export const BottomContainer = styled.div`
  left: 0;
  right: 0;
  bottom: 0;
  flex: 1;
  padding: 1rem;
  display: flex;
  position: fixed;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;
