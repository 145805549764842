import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { ExploreView } from "./explore/ExploreView";
import { MealPlanView } from "./meals/MealPlanView";
import { Profile } from "./profile";
import { ShoppingListInitial } from "./shopping/ShoppingListInitial";
import { MealView } from "./meals/MealView";
import { CreateShoppingList } from "./shopping/CreateShoppingList";
import { ShoppingList } from "./shopping/ShoppingList";
import { EditView } from "./profile/EditView";
import { Main } from "./auth";
import { LoginView } from "./auth/LoginView";
import { ForgotView } from "./auth/ForgotView";
import { MealPlanInitial } from "./meals/MealPlanInitial";
import { ChangeMealView } from "./meals/ChangeMealView";

const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />
  },
  {
    path: '/login',
    element: <LoginView />
  },
  {
    path: '/forgot',
    element: <ForgotView />
  },
  {
    path: "/explore",
    element: <ExploreView />,
  },
  {
    path: "/meals/initial",
    element: <MealPlanInitial />,
  },
  {
    path: "/meals/plan",
    element: <MealPlanView />,
  },
  {
    path: "/meals/change/:mealId",
    element: <ChangeMealView />,
  },
  {
    path: "/meals/plan/:mealId",
    element: <MealView />,
  },
  {
    path: "/shopping/initial",
    element: <ShoppingListInitial />,
  },
  {
    path: "/shopping/create",
    element: <CreateShoppingList />,
  },
  {
    path: "/shopping/list",
    element: <ShoppingList />
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/profile/:type",
    element: <EditView />,
  },
]);

export const Router = () => (
  <RouterProvider router={router} />
);
