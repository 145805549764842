import { FC } from "react";
import styled from "styled-components";
import { Colors } from "../../styles/global";
import { InfoSvg } from "../../assets/svg/InfoSvg";
import { ErrorSvg } from "../../assets/svg/ErrorSvg";

const Container = styled.div<{ $mb?: string, error?: boolean }>`
  flex: 1;
  display: flex;
  padding: 1rem;
  border-radius: 1rem;
  color: ${Colors.fontInfo};
  margin-bottom: ${p => p.$mb || '0'};
  background-color: ${p => p.error ? Colors.errorBg : Colors.secondaryBg};
`;

const InfoLabel = styled.span<{ error?: boolean }>`
  font-size: 14px;
  line-height: 1.5rem;
  padding-left: 0.5rem;
  color: ${p => p.error ? 'white' : Colors.fontInfo};
`;

interface Props {
  mb?: string;
  info: string;
  error?: boolean;
}

export const InfoItem: FC<Props> = ({
  mb,
  info,
  error,
}) => (
  <Container $mb={mb} error={error}>
    <div style={{ width: '100%', maxWidth: '24px', color: error ? 'white' : 'inherit' }}>
      {error ? <ErrorSvg/> : <InfoSvg />}
    </div>
    <InfoLabel error={error}>{info}</InfoLabel>
  </Container>
)
