export const Colors = {
  white: 'white',
  primary: '#f6c26e',
  fontPrimary: '#3d3d3d',
  fontInfo: '#848484',
  fontError: '#f01d1d',
  errorBg: 'red',
  bg: 'rgb(245, 245, 245)',
  secondaryBg: '#ffe8c2',
  btnSecondary: 'rgba(34, 34, 34, 0.2)',
  waterBlue: 'rgb(2, 122, 255)',
  waterBorder: (size = 1) => `${size}px solid rgb(2, 122, 255)`,
  primaryBorder: (size = 1) => `${size}px solid #f6c26e`,
}
