import styled from "styled-components";
import { TabBar } from "../../components/nav/TabBar";
import { ShoppingListHeader } from "../../components/nav/ShoppingListHeader";
import { ShoppingItem } from "../../components/items/ShoppingItem";
import { useShoppingList } from "../../state/shoppingList";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../styles/global";

const Content = styled.div`
  flex: 1;
  padding: 0 1rem;
  display: flex;
  padding-top: 90px;
  padding-bottom: 90px;
  flex-direction: column;
`;

const Button = styled.button`
  width: 100%;
  border: none;
  padding: 1rem;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 2rem;
  font-weight: 600;
  border-radius: 0.5rem;
  background-color: ${Colors.btnSecondary};
`;

export const ShoppingList = () => {
  const navigate = useNavigate();
  const { shoppingList, setPurchased, clearList } = useShoppingList();

  const onEdit = () => {
    navigate('/shopping/create');
  };

  return (
    <>
      <ShoppingListHeader onEdit={onEdit} />
      <Content>
        {shoppingList.ingredients?.map((item, i) => (
          <ShoppingItem
            key={i}
            {...item}
            onClick={() => setPurchased(i)}
          />
        ))}
        <Button onClick={clearList}>
          Clear list
        </Button>
      </Content>
      <TabBar />
    </>
  );
}
