import styled from "styled-components";
import { ChangeMealHeader } from "../../components/nav/ChangeMealHeader";
import { useMealPlan } from "../../state/mealPlan";
import { useParams, useSearchParams } from "react-router-dom";
import { ChangeMealCard } from "../../components/cards/ChangeMealCard";
import { PlateSvg } from "../../assets/svg/PlateSvg";
import { Colors } from "../../styles/global";

const Container = styled.div`
  height: 100vh;
  background-color: rgba(245, 245, 245, 1);
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  padding: 0 1rem;
  padding-top: 120px;
  flex-direction: column;
`;

const EmptyContainer = styled.div`
  display: flex;
  padding: 5rem 0;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: ${Colors.primary};
`;

const Title = styled.h2`
  max-width: 80%;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  color: ${Colors.fontPrimary};
`;

const Subtitle = styled.span`
  color: #666;
  max-width: 70%;
  font-size: 14px;
  text-align: center;
`;

export const ChangeMealView = () => {
  const { mealId } = useParams();
  const [params] = useSearchParams();
  const {
    changeMealTab,
    getLikedMeals,
    setChangeMealTab,
    getRecommendedMeals,
  } = useMealPlan();

  const day = params.get('day');
  const mealType = params.get('mealType');
  const mealsToRender = changeMealTab === 'favorites'
    ? getLikedMeals(mealId)
    : getRecommendedMeals(mealId);
  const showEmpty = changeMealTab === 'favorites' && mealsToRender.length === 0;
  
  return (
    <Container>
      <ChangeMealHeader
        title="Change Meal"
        active={changeMealTab}
        onTabChange={setChangeMealTab}
      />
      <Content>
        {mealsToRender.length > 0 && mealsToRender.map(m => (
          <ChangeMealCard
            day={day}
            key={m.mealId}
            mealId={m.mealId}
            mealType={mealType}
            mealToChangeId={mealId}
          />
        ))}
        {showEmpty && (
          <EmptyContainer>
            <PlateSvg />
            <Title>No favorites</Title>
            <Subtitle>
              You don’t have any meal marked as favorite
            </Subtitle>
          </EmptyContainer>
        )}
      </Content>
    </Container>
  )
}
