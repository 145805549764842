import { FC } from 'react';
import styled from 'styled-components';
import { EditSvg } from '../../assets/svg/EditSvg';

const Container = styled.div`
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
`;

const TopContent = styled.div`
  display: flex;
  padding: 1rem;
`;

const Title = styled.h1`
  font-size: 24px; /* Adjust the size as needed */
  color: #000;
  margin: 0;
`;

const EditButton = styled.button`
  margin-left: auto; /* Pushes the button to the right */
  background: none;
  border: none;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  font-weight: 600;
  cursor: pointer;

  svg {
    fill: #66589A;
    margin-bottom: -2px;
    margin-right: 5px;
  }
`;

interface Props {
  onEdit: () => void;
}

export const ShoppingListHeader: FC<Props> = ({ onEdit }) => (
  <Container>
    <TopContent>
      <Title>Shopping List</Title>
      <EditButton onClick={onEdit}>
        <EditSvg />
        EDIT
      </EditButton>
    </TopContent>
  </Container>
);
