import { getCurrentDate } from "./datetime"

export const sumDailyCalories = (
  caloriesConsumed?: Record<string, Record<string, number>>
) => {
  if (!caloriesConsumed) return 0;
  const date = getCurrentDate();
  const consumedToday = caloriesConsumed[date] || {};
  const calories = Object.values(consumedToday);
  return calories.reduce((pv, cv) => pv += cv, 0);
}
