import { create } from "zustand";
import { immer } from 'zustand/middleware/immer'
import { persist } from 'zustand/middleware'
import { Api } from "../apis/api";
import { IProfile } from "../types/profile";
import { differenceInMinutes, format } from 'date-fns';
import { getLast7DaysData } from "../utils/datetime";
import { kgToPounds } from "../utils/profile";

const STORAGE_KEY = 'profile';

interface ILoginData {
  email: string;
  password: string;
}

interface IChartData {
  goal: number;
  weight: number;
  latestTracked: number;
  weights: number[];
  datetime: string[];
}

export interface IProfileState {
  profile: IProfile | null;
  lastFetched: string | null;
  login: (creds: ILoginData) => Promise<IProfile>;
  update: (profile: Partial<IProfile>) => Promise<void>;
  getWeightGraph: () => IChartData;
  refetchUser: () => Promise<void>;
  resetProfile: () => void;
}

export const useProfile = create<IProfileState>()(
  immer(
    persist((set, get) => ({
      profile: null,
      lastFetched: null,
      async login(creds) {
        const profile = await Api.login(creds.email, creds.password);
        set(s => {
          s.profile = profile;
          s.lastFetched = new Date().toISOString();
        });
        return profile;
      },
      async update(profile) {
        try {
          const oldProfile = get().profile;
          const update = {
            ...oldProfile,
            ...profile,
            id: oldProfile._id,
            _id: oldProfile._id,
          };
          set(s => {
            s.profile = update;
          });
          await Api.update(update);
        } catch(e) {
          Api.reportError(e, {
            fn: 'onChangeMeal',
            email: profile.email,
          });
        }
      },
      resetProfile() {
        localStorage.removeItem(STORAGE_KEY);
        set(s => {
          s.profile = null;
          s.lastFetched = null;
        });
      },
      async refetchUser() {
        const { lastFetched, profile } = get();
        const now = new Date();
        const lastFetchedDate = new Date(lastFetched);
        const diff = differenceInMinutes(now, lastFetchedDate);
        console.log('userDiff', diff);
        if (diff > 3) {
          const current = await Api.user(profile._id);
          set(s => {
            s.profile = current;
            s.lastFetched = new Date().toISOString();
          });
        }
      },
      getWeightGraph() {
        const { profile } = get();
        const units = profile.units
        const goal = profile.goal;
        const startDate = format(profile.createdAt, 'yyyy-MM-dd');
        const trackedWeights = { ...profile.weightTracked };
        trackedWeights[startDate] = profile.weight;
        const last7 = getLast7DaysData(trackedWeights);
        const datetime = Object.keys(last7);
        const weights = Object.values(last7);

        return {
          datetime,
          latestTracked: units === 'metric' ? weights[weights.length - 1] : kgToPounds(weights[weights.length - 1]),
          goal: units === 'metric' ? goal : kgToPounds(goal),
          weight: units === 'metric' ? profile.weight : kgToPounds(profile.weight),
          weights: units === 'metric' ? weights : weights.map(w => kgToPounds(w))
        };
      }
    }),
      {
        name: STORAGE_KEY,
      }
    )
  )
);
