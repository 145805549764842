
export const parseOutCm = (str: string) => {
  const cmPart = str.split(' - ')[1]; // Splitting the string and taking the part after ' - '
  return parseInt(cmPart.slice(0, -3)); // Removing ' cm' and converting to integer
}

export const convertHeightToStr = (cm: number) => {
  const totalInches = cm / 2.54;
  const feet = Math.floor(totalInches / 12);
  const inches = Math.floor(totalInches % 12);

  return `${feet} ft ${inches} in - ${Math.floor(cm)} cm`;
}
