import * as yup from 'yup';

const emailRe =
  /^([\w-.+]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,253})\.([a-z]{2,10}(?:\.[a-z]{2})?)$/i;

export const validateEmail = (value: unknown) => {
  if (typeof value === 'string') {
    return emailRe.test(value);
  }
  return false;
};

const login = yup.object().shape({
  email: yup
    .string()
    .email('Invalid email address') // Validates the format of the email
    .required('Email is required'), // Ensures the email is provided
  password: yup
    .string()
    .min(6, 'Password must be at least 6 characters') // Validates minimum length
    .required('Password is required'), // Ensures the password is provided
});

const profile = yup.object({
  name: yup
    .string()
    .trim()
    .required('Name is required'),
  surname: yup
    .string()
    .trim()
    .required('Surname is required'),
});

export const v = {
  login,
  profile,
}
