import { useEffect, useRef, useCallback, FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

const Backdrop = styled.div<{ open: boolean }>`
  display: ${p => (p.open ? 'block' : 'none')};
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Container = styled.div<{ open: boolean }>`
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  position: fixed;
  background: white;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  transition: transform 0.3s ease-in-out;
  transform: translateY(${p => (p.open ? '0' : '100%')});
`;

const Handle = styled.div`
  height: 20px;
  display: flex;
  position: relative;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    top: 8px;
    width: 40px;
    height: 4px;
    background-color: #cccccc;
    border-radius: 2px;
  }
`;

interface Props extends PropsWithChildren {
  isOpen: boolean;
  onClose: () => void;
}

export const ActionSheet: FC<Props> = ({
  isOpen,
  onClose,
  children
}) => {
  const sheetRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (sheetRef.current && !sheetRef.current.contains(event.target as Node)) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  return (
    <>
      <Backdrop open={isOpen} onClick={onClose} />
      <Container open={isOpen} ref={sheetRef}>
        <Handle />
        {children}
      </Container>
    </>
  );
};
