export const ProfileSvg = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <path fillRule="evenodd" clipRule="evenodd" d="M7 7C7 4.2 9.2 2 12 2C14.8 2 17 4.2 17 7C17 9.8 14.8 12 12 12C9.2 12 7 9.8 7 7ZM21 19V21C21 21.6 20.6 22 20 22C19.4 22 19 21.6 19 21V19C19 17.3 17.7 16 16 16H8C6.3 16 5 17.3 5 19V21C5 21.6 4.6 22 4 22C3.4 22 3 21.6 3 21V19C3 16.2 5.2 14 8 14H16C18.8 14 21 16.2 21 19ZM12 10C10.3 10 9 8.7 9 7C9 5.3 10.3 4 12 4C13.7 4 15 5.3 15 7C15 8.7 13.7 10 12 10Z" />
    <mask id="mask0_2950_3734" maskUnits="userSpaceOnUse" x="3" y="2" width="18" height="20">
      <path fillRule="evenodd" clipRule="evenodd" d="M7 7C7 4.2 9.2 2 12 2C14.8 2 17 4.2 17 7C17 9.8 14.8 12 12 12C9.2 12 7 9.8 7 7ZM21 19V21C21 21.6 20.6 22 20 22C19.4 22 19 21.6 19 21V19C19 17.3 17.7 16 16 16H8C6.3 16 5 17.3 5 19V21C5 21.6 4.6 22 4 22C3.4 22 3 21.6 3 21V19C3 16.2 5.2 14 8 14H16C18.8 14 21 16.2 21 19ZM12 10C10.3 10 9 8.7 9 7C9 5.3 10.3 4 12 4C13.7 4 15 5.3 15 7C15 8.7 13.7 10 12 10Z" />
    </mask>
    <g mask="url(#mask0_2950_3734)">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0H24V24H0V0Z" />
    </g>
  </svg>
);
