import { FC } from "react";
import styled from "styled-components"
import { CheckSvg } from "../../assets/svg/CheckSvg";
import { Colors } from "../../styles/global";

const Container = styled.div<{ $active?: boolean }>`
  display: flex;
  min-width: 1.25rem;
  min-height: 1.25rem;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${p => p.$active ? Colors.primary : 'none'};
  border: ${p => p.$active ? Colors.primaryBorder(2) : '2px solid rgba(204, 204, 204, 1)'};
`;

interface Props {
  active?: boolean;
}

export const Checkbox: FC<Props> = ({ active }) => (
  <Container $active={active}>
    {active && <CheckSvg/>}
  </Container>
)
