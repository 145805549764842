import { FC } from 'react';
import styled from 'styled-components';
import { Colors } from '../../styles/global';
import { IProfile } from '../../types/profile';
import { getFullName, getInitials } from '../../utils/profile';

const Container = styled.div`
  width: 100%;
  display: flex;
  min-height: 30vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 10%;
  border-bottom-right-radius: 10%;
  background-color: ${Colors.primary};
`;

const Avatar = styled.div`
  width: 70px;
  height: 70px;
  display: flex;
  margin: 1rem 0;
  font-weight: 700;
  font-size: 1.5rem;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: ${Colors.fontPrimary};
  background-color: rgba(240, 238, 245, 1);
  border: 3px solid rgba(255, 255, 255, 1);
`;

const Title = styled.h1`
  margin: 0;
  font-size: 1rem;
  color: ${Colors.fontPrimary};
`;

const UserName = styled.h1`
  margin: 0;
  font-weight: 500;
  font-size: 1.5rem;
  color: ${Colors.fontPrimary};
`;

export const ProfileHeader: FC<IProfile> = (profile) => {
  return (
    <Container>
      <Title>My Profile</Title>
      <Avatar>
        {getInitials(profile)}
      </Avatar>
      <UserName>{getFullName(profile)}</UserName>
    </Container>
  );
}
