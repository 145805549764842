import { FC, useState } from "react";
import { ActionSheet } from "./ActionSheet"
import styled from "styled-components";
import { Counter } from "../inputs/Counter";
import { Colors } from "../../styles/global";
import { LoaderSvg } from "../../assets/svg/LoaderSvg";
import { convertOuncesToMl } from "../../utils/units";

const Container = styled.div`
  gap: 2rem;
  display: flex;
  padding: 1rem 2rem;
  padding-bottom: 2rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.span`
  color: #666;
  max-width: 100%;
  font-size: 1rem;
  text-align: center;
`;

const Button = styled.button`
  width: 100%;
  border: none;
  min-height: 50px;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  border-radius: 0.5rem;
  color: ${Colors.white};
  background-color: ${Colors.waterBlue};
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (value: number) => void;
  loading?: boolean;
  currentIntake?: number;
}

export const WaterSheetImperial: FC<Props> = ({
  isOpen,
  onClose,
  onSave,
  loading,
  currentIntake = 68,
}) => {
  const [intake, setIntake] = useState(currentIntake);

  const submit = () => {
    onSave(convertOuncesToMl(intake));
  }

  return (
    <ActionSheet
      isOpen={isOpen}
      onClose={onClose}
    >
      <Container>
        <Title>
          Modify your daily water intake goal
        </Title>
        <Counter
          min={16}
          step={8}
          unit="fl oz"
          initial={intake}
          onChange={v => setIntake(v)}
        />
        <Button onClick={submit}>
          {loading ? <LoaderSvg /> : 'Save'}
        </Button>
      </Container>
    </ActionSheet>
  )
}
